<template>
  <ComplaintsDetail :user="user" />
</template>
<script>
import ComplaintsDetail from "@/components/Dashboard/DuplicateComplaints/ComplaintsDetail.vue";
export default {
  components: { ComplaintsDetail },
  props: {
    user: { type: Object, require: true },
  },
  name: "ComplaintsDetailView",
  data() {
    return {};
  },
};
</script>